<template>
  <div class="hero-banner-ten">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-11 col-md-8 m-auto">
          <h2 class="titre-section">Vous souhaitez
            <span class="span-background">
              nous contacter ?
            </span>
          </h2>
          <p class="hero-sub-heading">
            Contactez-nous dès maintenant et parlons ensemble de votre projet.
          </p>
        </div>
      </div>
      <div class="d-sm-flex align-items-center justify-content-center button-group">
        <a href="/contactez-nous" class="d-flex align-items-center contact">
          Formulaire de contact
          <i class="fa-solid fa-arrow-right" data-v-636dcde4=""></i>
        </a>
      </div>
    </div>
    <div class="icon-box-one">
      <i>
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="30.5" cy="30.8047" rx="30" ry="30" transform="rotate(90 30.5 30.8047)" fill="#F4989D" />
        </svg>
      </i>
    </div>
    <div class="icon-box-two">
      <i>
        <svg width="99" height="87" viewBox="0 0 99 87" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M49.5 0.804688L98.8634 86.3047H0.136551L49.5 0.804688Z" fill="#FAB74B" />
        </svg>
      </i>
    </div>
    <div class="icon-box-three">
      <i>
        <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="47.2854" y="2.2189" width="64.1646" height="64.1646" transform="rotate(45 47.2854 2.2189)"
            stroke="url(#paint0_linear_1305_2153)" stroke-width="2" />
          <defs>
            <linearGradient id="paint0_linear_1305_2153" x1="47.2854" y1="1.10337" x2="117.016" y2="5.10298"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0989583" stop-color="#318EC7" />
              <stop offset="0.52721" stop-color="#E94B54" />
              <stop offset="0.880208" stop-color="#FAB74B" />
            </linearGradient>
          </defs>
        </svg>
      </i>
    </div>
    <div class="icon-box-four">
      <i>
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="21.5" cy="21.8047" r="21" transform="rotate(180 21.5 21.8047)" fill="#318EC7" />
        </svg>
      </i>
    </div>
    <div class="icon-box-five">
      <i>
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="21.5" cy="21.8047" r="21" transform="rotate(180 21.5 21.8047)" fill="#318EC7" />
        </svg>
      </i>
    </div>
    <div class="icon-box-six">
      <i>
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="54.3496" width="75.7239" height="75.7239" transform="rotate(-45 0.5 54.3496)"
            fill="#F4989D" />
        </svg>
      </i>
    </div>
    <div class="icon-box-seven">
      <i>
        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1.91421" y="29.2539" width="38.234" height="38.234" transform="rotate(-45 1.91421 29.2539)"
            stroke="url(#paint0_linear_1305_2148)" stroke-width="2" />
          <defs>
            <linearGradient id="paint0_linear_1305_2148" x1="0.5" y1="29.4355" x2="42.9022" y2="31.8677"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0989583" stop-color="#318EC7" />
              <stop offset="0.52721" stop-color="#E94B54" />
              <stop offset="0.880208" stop-color="#FAB74B" />
            </linearGradient>
          </defs>
        </svg>
      </i>
    </div>
    <div class="icon-box-eight">
      <i>
        <svg width="70" height="61" viewBox="0 0 70 61" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35 0.804681L69.208 60.0547L0.792006 60.0547L35 0.804681Z" fill="#FAB74B" />
        </svg>
      </i>
    </div>
  </div>
</template>

<script setup>
</script>
<style scoped>
.titre-section {
  color: var(--accent, #25233E);
  text-align: center;
  font-family: Nunito;
  font-size: 3.125rem;
  /*50 px */
  font-style: normal;
  font-weight: 600;
  line-height: 111.5%;
  letter-spacing: -0.825px;
  text-transform: uppercase;
}

.icon-box-one,
.icon-box-two,
.icon-box-three,
.icon-box-four,
.icon-box-five,
.icon-box-six,
.icon-box-seven,
.icon-box-eight {
  background: transparent;
}


.contact {
  background-color: var(--bleu-fonce) !important;
  border: 2px solid var(--bleu-fonce);
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 35px !important;
  width: auto !important;
  column-gap: 1rem;
}

.contact:hover {
  background-color: transparent !important;
  border: 2px solid var(--bleu-fonce) !important;
  color: var(--bleu-fonce) !important;
}

.hero-banner-ten {
  padding: 5rem 0;
  margin-bottom: 120px;
}

.hero-sub-heading {
  font-family: 'Inter';
  font-weight: 600;
}


@media only screen and (min-width: 300px) and (max-width: 767px) {
  .hero-banner-ten {
    padding: 0rem 0;
  }

  .span-background {
    white-space: nowrap;
  }

  .hero-sub-heading {
    font-size: 15px;
    padding: 20px 0 20px;
  }

  .contact {
    font-size: 90%;
    width: 80% !important;
  }

  .d-flex.justify-content-center {
    max-width: 80%;
    margin: auto;
    text-align: center;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

  .button-group {
    transform: scale(0.9);
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .hero-banner-ten {
    padding: 0rem 0;
  }

  .span-background {
    white-space: nowrap;
  }

  .hero-sub-heading {
    font-size: 18px;
    padding: 20px 0 20px;
  }

  .contact {
    font-size: 18px;
  }

  .d-flex.justify-content-center {
    max-width: 100%;
    margin: auto;
    text-align: center;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

  .button-group {
    transform: scale(0.9);
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .hero-banner-ten {
    padding: 0rem 0;
  }

  .span-background {
    white-space: nowrap;
  }

  .hero-sub-heading {
    font-size: 18px;
    padding: 20px 0 20px;
  }

  .contact {
    font-size: 18px;
    width: 30% !important;
    white-space: nowrap;
  }

  .d-flex.justify-content-center {
    max-width: 100%;
    margin: auto;
    text-align: center;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }


  .button-group {
    transform: scale(0.9);
  }



  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-banner-ten {
    padding: 0rem 0;
  }

  .span-background {
    white-space: nowrap;
  }

  .hero-sub-heading {
    font-size: 18px;
    padding: 20px 0 20px;
  }


  .contact {
    font-size: 18px;
    width: 25% !important;
    white-space: nowrap;
  }

  .d-flex.justify-content-center {
    max-width: 100%;
    margin: auto;
    text-align: center;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }


  .button-group {
    transform: scale(0.9);
  }



  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

  .icon-box-one,
  .icon-box-two,
  .icon-box-three,
  .icon-box-four,
  .icon-box-five,
  .icon-box-six,
  .icon-box-seven,
  .icon-box-eight {
    width: 20% !important;
  }

  .icon-box-one i svg,
  .icon-box-two i svg,
  .icon-box-three i svg,
  .icon-box-four i svg,
  .icon-box-five i svg,
  .icon-box-six i svg,
  .icon-box-seven i svg,
  .icon-box-eight i svg {
    width: 30% !important;
  }

  .icon-box-one {
    top: -33%;
    left: 4%;
  }

  .icon-box-two {
    top: -4%;
    left: -4%;
  }

  .icon-box-three {
    top: 23%;
    left: 6%;
  }

  .icon-box-four {
    top: 72%;
    left: -2%;
  }

  .icon-box-five {
    top: -35%;
    right: 2%;
  }

  .icon-box-six {
    top: 2%;
    right: -5%;
  }

  .icon-box-seven {
    top: 35%;
    right: 10%;
  }

  .icon-box-eight {
    top: 55%;
    right: 2%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .hero-banner-ten {
    padding: 0rem 0;
  }

  .span-background {
    white-space: nowrap;
  }

  .hero-sub-heading {
    font-size: 18px;
    padding: 20px 0 20px;
  }


  .contact {
    font-size: 18px;
    white-space: nowrap;
  }

  .d-flex.justify-content-center {
    max-width: 100%;
    margin: auto;
    text-align: center;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }


  .button-group {
    transform: scale(0.9);
  }



  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

  .icon-box-one {
    top: -33%;
    left: 10%;
  }

  .icon-box-two {
    top: -4%;
    left: 2%;
  }

  .icon-box-three {
    top: 23%;
    left: 16%;
  }

  .icon-box-four {
    top: 72%;
    left: 8%;
  }

  .icon-box-five {
    top: -35%;
    right: 12%;
  }

  .icon-box-six {
    top: 2%;
    right: 5%;
  }

  .icon-box-seven {
    top: 35%;
    right: 20%;
  }

  .icon-box-eight {
    top: 55%;
    right: 12%;
  }
}

@media screen and (max-width: 991px) {

  /* Styles spécifiques pour les écrans de petite taille */
  .icon-box-one,
  .icon-box-two,
  .icon-box-three,
  .icon-box-four,
  .icon-box-five,
  .icon-box-six,
  .icon-box-seven,
  .icon-box-eight {
    display: none;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .span-background {
    white-space: nowrap;
  }

  .section-padding {
    padding-bottom: 0;
    padding-top: 3rem;
  }

  .bloctitleText {
    font-family: Inter;
    font-size: 0.8rem;
    line-height: normal;
  }

  .d-flex.justify-content-center {
    max-width: 80%;
    margin: auto;
    text-align: center;
  }

  .btn-transparent {
    font-size: 14px;
    padding: 8px 12px;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

  .add-padding {
    padding-bottom: 3rem;
  }

  .button-group {
    transform: scale(0.9);
  }

  .contact {
    font-size: 90%;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

}

@media only screen and (min-width: 1300px) {
  
}
</style>
